import React from 'react'
// import Swiper from 'react-id-swiper'
import SwiperCore, { Pagination, Navigation, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import useSwiperA11y from '../../utils/useSwiperA11y'

// For some reaseon scss is not ending in the final build
// import 'swiper/swiper.scss'
// import 'swiper/components/a11y/a11y.scss'
// import 'swiper/components/controller/controller.scss'
// import 'swiper/components/pagination/pagination.scss'
// import 'swiper/components/navigation/navigation.scss'

// import '../../style/controller.css'
import '../../style/swiper.css'
import '../../style/a11y.css'
import '../../style/pagination.css'
import '../../style/navigation.css'

SwiperCore.use([Pagination, Navigation, A11y])

interface Props {
  contents: Array<React.ReactNode>
  swiperHandlers: any
}

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 1.5rem;
  padding-bottom: 0;
  box-sizing: border-box;
  // width: 100%;
`
const Box = styled.div`
  background: white;
  /* We cannot use drop-shadow here because Safari has major performance issues while swiping otherwise */
  /* filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.07))
    drop-shadow(0 2px 2px rgba(0, 0, 0, 0.07))
    drop-shadow(0 4px 4px rgba(0, 0, 0, 0.07))
    drop-shadow(0 8px 8px rgba(0, 0, 0, 0.07))
    drop-shadow(0 16px 16px rgba(0, 0, 0, 0.07))
    drop-shadow(0 32px 32px rgba(0, 0, 0, 0.07)); */
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  box-sizing: border-box;
  padding: 1.5rem;
  max-width: 500px;
  /* user-select: none; */
  position: relative;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 30px;
    border-color: transparent transparent white transparent;
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -30px;
  }
`

const ContentSlider: React.FC<Props> = (props: Props) => {
  const { contents, swiperHandlers } = props
  const a11y = useSwiperA11y('landing.testimonialSliderTopic')

  /*
  const swiperProps = {
    roundLengths: true,
    speed: 600,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 15000,
      disableOnInteraction: true,
    },
    keyboard: {
      enabled: true,
    },
    a11y,
    on: swiperHandlers,
  }
  */

  return (
    <Swiper
      roundLengths
      speed={600}
      loop
      pagination={{
        // el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      }}
      navigation={
        {
          // nextEl: '.swiper-button-next',
          // prevEl: '.swiper-button-prev',
        }
      }
      autoplay={{ delay: 15000, disableOnInteraction: true }}
      keyboard={{ enabled: true }}
      a11y={a11y}
      onSlideChange={swiperHandlers.slideChange}
    >
      {contents.map((content, idx) => (
        <SwiperSlide key={idx}>
          <Slide className={'visible'}>
            <Box>{content}</Box>
          </Slide>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default React.memo(ContentSlider)
