import React from 'react'
import styled from 'styled-components'
import Label from './Label'

const Wrapper = styled.div<{ width: number; swiper: boolean }>`
  position: ${props => (props.swiper ? 'relative' : 'absolute')};
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  height: auto;
  svg {
    width: 100%;
  }
`

interface Props {
  children: React.ReactNode
  x?: number
  y?: number
  width: number
  labelPoints: Point[]
  labelPosition: Point
  labelText: string
  hideCircle?: boolean
  bubble: React.ReactNode
  swiper: boolean
  isActiveSlide: boolean
  className?: string
}

const Graphic: React.FC<Props> = (props: Props) => {
  const [hover, setHover] = React.useState(false)
  const [clicked, setClicked] = React.useState(false)
  const {
    children,
    x,
    y,
    labelPoints,
    labelPosition,
    labelText,
    width,
    bubble,
    hideCircle,
    swiper,
    isActiveSlide,
    className,
  } = props
  const style: React.CSSProperties = {}
  if (!swiper) {
    style.left = x ? `${x}px` : 0
    style.top = y ? `${y}px` : 0
  }
  return (
    <Wrapper
      width={width}
      style={style}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setClicked(!clicked)}
      swiper={swiper}
      className={className}
      aria-hidden="true"
    >
      {children}
      <Label
        points={labelPoints}
        labelPosition={labelPosition}
        active={/* swiper && */ isActiveSlide || clicked || hover}
        hideCircle={!swiper && hideCircle}
        bubble={bubble}
      >
        {labelText}
      </Label>
    </Wrapper>
  )
}

export default Graphic
