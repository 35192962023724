import React, { forwardRef } from "react";
import styled from "styled-components";

interface Props {
  logos: Array<React.ReactNode>;
  // activeIdx: number;
}

export const CIRCLE_DIAMETER = 700;
const ITEM_DIAMETER = 100;

const Wrapper = styled.div`
  width: 700px;
  height: 700px;
  border-radius: 50%;
  border: solid 5px rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  position: relative;

  top: -480px;
  left: 50%;
  margin-left: -350px;
  margin-bottom: -${500 - ITEM_DIAMETER}px;
`;
const Logo = styled.div`
  width: ${ITEM_DIAMETER}px;
  height: ${ITEM_DIAMETER}px;
  margin-top: ${-ITEM_DIAMETER / 2}px;
  margin-left: ${-ITEM_DIAMETER / 2}px;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.5s ease-in-out;
  background: white;
  border-radius: 50%;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07); */
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
  overflow: hidden;
  /* will-change: transform; */
`;

const LogoCircle = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { logos /* , activeIdx */ } = props;

  if (!logos.length) {
    return null;
  }
  // const arc = 360 / logos.length;

  return (
    <Wrapper ref={ref}>
      {logos.map((logo, idx) => {
        // const angle = arc * (idx - activeIdx) - 90;
        return (
          <Logo key={idx} className="circle">
            {/* style={{
              transform: `rotate(${-angle}deg) translate3d(${CIRCLE_DIAMETER /
                2}px, 0, 0) rotate(${angle}deg)`,
            }} */}
            {logo}
          </Logo>
        );
      })}
    </Wrapper>
  );
});

export default LogoCircle;
