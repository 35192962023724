import React from 'react'
import styled from 'styled-components'

const CIRCLE_RADIUS = 10

const Line = styled.path<{ pathLength: number }>`
  stroke: white;
  stroke-width: 2;
  fill: transparent;
  stroke-dasharray: ${props => props.pathLength};
  stroke-dashoffset: ${props => props.pathLength};
  transition: stroke-dashoffset 0.5s ease-in-out;
`
const Text = styled.text`
  font-family: ${props => props.theme.fontFamily};
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.scale ** -11}em;
  fill: rgba(255, 255, 255, 0);
  transition: fill 0.25s ease-in-out;
  @media only screen and (max-width: ${props =>
      props.theme.protraitBreakpoint}px) {
    letter-spacing: ${props => props.theme.scale ** -25}em;
  }
`
const BubbleWrap = styled.g`
  opacity: 0;
  filter: url(#drop-shadow);
  transition: opacity 0.25s ease-in-out;
`
const AttentionCircle = styled.circle<{ hideCircle?: boolean }>`
  stroke: ${props => (props.hideCircle ? 'transparent' : 'white')};
  stroke-width: 2;
  fill: ${props =>
    props.hideCircle ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0.1)'};
  filter: url(#drop-shadow);
  transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
`
const Wrapper = styled.svg`
  overflow: visible;
  position: relative;
  width: 1px;
  &.active {
    ${AttentionCircle} {
      stroke: white;
      fill: rgba(255, 255, 255, 0.1);
      filter: none;
    }
    ${BubbleWrap} {
      opacity: 1;
      transition-duration: 0.3s;
    }
    ${Line} {
      stroke-dashoffset: 0;
    }
    ${Text} {
      transition-delay: 0.3s;
      fill: rgba(255, 255, 255, 1);
    }
  }
`

interface Props {
  active: boolean
  hideCircle?: boolean
  points: Point[]
  labelPosition: Point
  children: React.ReactNode
  bubble: React.ReactNode
}

const Label: React.FC<Props> = (props: Props) => {
  const { active, hideCircle, points, labelPosition, children, bubble } = props
  const pathLength =
    points.reduce((val, p2, idx) => {
      if (idx < 1) {
        return 0
      }
      const p1 = points[idx - 1]
      return (
        val + Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
      )
    }, 0) - CIRCLE_RADIUS
  return (
    <Wrapper className={active ? 'active' : ''}>
      <filter id="drop-shadow">
        <feGaussianBlur
          in="SourceAlpha"
          stdDeviation="1"
          result="blur"
        ></feGaussianBlur>
        <feOffset in="blur" dx="0" dy="0" result="offsetBlur"></feOffset>
        <feMerge>
          <feMergeNode in="offsetBlur"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <BubbleWrap>{bubble}</BubbleWrap>
      <AttentionCircle
        hideCircle={hideCircle}
        cx={points[0].x}
        cy={points[0].y}
        r={CIRCLE_RADIUS}
      />
      <Line
        pathLength={pathLength}
        d={points
          .map((v, idx) => {
            if (idx === 0 && points[1]) {
              let { x, y } = v
              const nX = points[1].x
              const nY = points[1].y
              if (nX > x) {
                x += CIRCLE_RADIUS
              } else if (nX < x) {
                x -= CIRCLE_RADIUS
              } else if (nY > y) {
                y += CIRCLE_RADIUS
              } else if (nY < y) {
                y -= CIRCLE_RADIUS
              }
              return `M ${x} ${y}`
            }
            return `L ${v.x} ${v.y}`
          })
          .join(' ')}
      />
      <Text x={labelPosition.x} y={labelPosition.y}>
        {children}
      </Text>
    </Wrapper>
  )
}

export default Label
