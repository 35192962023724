import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import LogoCircle, { CIRCLE_DIAMETER } from './LogoCircle'
import { TestimonialsFragmentFragment } from '../../../types/graphql-types'
import ContentSlider from './ContentSlider'
import GatsbyImage from 'gatsby-image'
// import Markdown from '../Markdown'
import Header from '../Header'
import Background from './Background'
import Testimonial from './Testimonial'
import Swiper from 'swiper'

interface Props {
  data: Array<TestimonialsFragmentFragment>
}

export const query = graphql`
  fragment TestimonialsFragment on CMS_Solution {
    id
    Company
    Title
    Description
    Logo {
      id
      alternativeText
      caption
      url
      urlSharp {
        childImageSharp {
          fixed(
            fit: CONTAIN
            width: 100
            height: 100
            background: "rgba(255,255,255,1)"
            quality: 100
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
    Testimonial {
      id
      Author
      AuthorRole
      Quote
      QuoteExcerpt
    }
  }
`

const Wrapper = styled.section`
  overflow: hidden;
  width: 100vw;
  position: relative;
  z-index: 1;
  padding-bottom: 1.5rem;
  ${Header} {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 3rem;
  }
  --swiper-theme-color: white;
  .swiper-wrapper {
    will-change: transform;
  }
  .swiper-pagination-bullet {
    background: white;
  }
  .swiper-slide {
    height: auto;
    margin-bottom: 1.5rem;
  }
  .swiper-wrapper {
    padding-bottom: 3rem;
  }
  .swiper-pagination {
    bottom: 1.5rem;
  }
  .swiper-button-prev {
    left: 30px;
  }
  .swiper-button-next {
    right: 30px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    margin-top: -150px;
    &:focus {
      outline: none;
      &:after {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 0 0 4px rgb(204, 204, 204);
      }
    }
    &:after {
      flex-shrink: 0;
      background: rgba(255, 255, 255, 0.3);
      color: #333;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      transition: background 0.25s linear;
    }
    &:hover:after {
      background: rgba(255, 255, 255, 1);
    }
    @media only screen and (max-width: ${props =>
        props.theme.protraitBreakpoint}px) {
      display: none;
    }
  }
`

const Testimonials: React.FC<Props> = (props: Props) => {
  const { data } = props
  const circlesRef = React.useRef<HTMLDivElement>(null)
  const activeIdxRef = React.useRef(-1)
  const intl = useIntl()
  const dataHash = data.map(({ id }) => id).join('_')

  // To get 60fps animations we break out of react's declarative pattern
  // and manually rotate the logos so that changing slides won't lead
  // to rerenders in react
  const swiperHandlers = React.useMemo(() => {
    const circlesWrapper = circlesRef.current
    if (!circlesWrapper) {
      return null
    }

    const arc = 360 / data.length

    const rotateItem = (el: HTMLDivElement, idx: number) => {
      const activeIdx = activeIdxRef.current
      const angle = arc * (idx - activeIdx) - 90
      el.style.transform = `rotate(${-angle}deg) translate3d(${
        CIRCLE_DIAMETER / 2
      }px, 0, 0) rotate(${angle}deg)`
    }

    const items = circlesWrapper.querySelectorAll<HTMLDivElement>('.circle')

    const rotateItems = () => {
      for (let i = 0; i < items.length; ++i) {
        rotateItem(items[i], i)
      }
    }
    // Initially transform all items
    // items.forEach(rotateItem)
    rotateItems()

    return {
      slideChange: function () {
        const swiper = (this as any) as Swiper
        const { activeIndex, previousIndex } = swiper
        const offset = activeIndex - previousIndex
        // When looping over the borders (first or last item)
        // This event will be called twice... once with the real offset of the full (+-) items count
        // and a second time with normalized values
        // To prevent a full circle rotation we skip the first event and only use the normalized one
        if (Math.abs(offset) === data.length) {
          // offset === data.length => going backwards over the first item
          // offset === -data.length => going forwards over the last item
          return
        }
        // console.log({ offset, activeIndex, previousIndex })
        activeIdxRef.current += offset
        // items.forEach(rotateItem)
        rotateItems()
        // console.log(offset, activeIdxRef.current)
      },
      slideNextTransitionStart: () => {
        // console.log('Transition started...')
        // activeIdxRef.current += 1
        // items.forEach(rotateItem)
      },
      slidePrevTransitionStart: () => {
        // activeIdxRef.current -= 1
        // items.forEach(rotateItem)
      },
    }
  }, [dataHash, activeIdxRef, circlesRef.current])
  // Map the logos to memoized gatsby images
  const logos = React.useMemo(() => {
    return data.map(item => {
      const { Logo, Company } = item
      const fixed = Logo?.urlSharp?.childImageSharp?.fixed
      if (!fixed) {
        return <div />
      }
      return (
        <GatsbyImage
          fixed={fixed as any}
          loading="eager"
          alt={Logo?.alternativeText || Company || ''}
          title={Logo?.caption || Company || ''}
          key={item.id}
        /> // backgroundColor="transparent"
      )
    })
  }, [dataHash])
  // Map the contents to memoized elements
  const contents = React.useMemo(() => {
    return data.map(item => {
      const { Title, Description, Testimonial: testimonial, Company } = item
      return (
        <Testimonial
          title={Title || ''}
          description={Description || ''}
          testimonial={testimonial!}
          company={Company || ''}
          key={item.id}
        />
      )
    })
  }, [dataHash])

  if (data.length < 1) {
    return null
  }

  return (
    <Background>
      <Wrapper>
        <Header as="h1">
          {intl.formatMessage({ id: 'landing.testimonial' })}
        </Header>
        <LogoCircle logos={logos} ref={circlesRef} />
        {swiperHandlers && (
          <ContentSlider contents={contents} swiperHandlers={swiperHandlers} />
        )}
      </Wrapper>
    </Background>
  )
}

export default Testimonials
