import React from 'react'
import styled from 'styled-components'
import Graphic from './Graphic'
import TTS from '../../assets/tts.svg'
import ASR from '../../assets/asr.svg'
import IVR from '../../assets/ivr.svg'
import Cat from '../../assets/cat.svg'
import Bubble from '../../assets/bubble.svg'
import Bubbles from '../../assets/bubbles.svg'
import { useIntl } from 'gatsby-plugin-intl'
import useMedia from 'use-media'
// import Swiper, { SwiperInstance } from 'react-id-swiper'
import { useInView } from 'react-intersection-observer'
import useSwiperA11y from '../../utils/useSwiperA11y'
// import "swiper/css/swiper.css";
// import 'swiper/swiper-bundle.css'
import SwiperCore, { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// import 'swiper/swiper.scss'
// import 'swiper/components/a11y/a11y.scss'
// import 'swiper/components/controller/controller.scss'
// import 'swiper/components/pagination/pagination.scss'
//// import 'swiper/swiper-bundle.min.css'

import '../../style/swiper.css'
import '../../style/a11y.css'
import '../../style/pagination.css'

SwiperCore.use([Pagination, A11y])

const Wrapper = styled.div`
  position: relative;
  width: 580px;
  height: 505px;
  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
`
const SwiperWrapper = styled.div`
  --swiper-theme-color: white;
  .swiper-pagination-bullet {
    background: white;
  }
`
const Slide = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-top: 5rem;
  margin-bottom: -3rem;
`

const Graphics: React.FC = () => {
  const intl = useIntl()
  const a11y = useSwiperA11y()
  const showSwiper = useMedia({ maxWidth: '630px' })
  const [swiper, setSwiper] = React.useState<SwiperCore | null>(null)
  const [autoLabel, setAutoLabel] = React.useState(0)
  React.useEffect(() => {
    const timer = setTimeout(() => {
      let newLabel = autoLabel + 1
      if (newLabel >= 3) {
        newLabel = 0
      }
      setAutoLabel(newLabel)
    }, 4000)
    return () => clearTimeout(timer)
  }, [autoLabel, setAutoLabel])

  const [ref, inView] = useInView()
  React.useEffect(() => {
    if (!swiper) {
      return
    }
    if (!inView) {
      swiper.autoplay?.stop()
      // console.log('Stopping autoplay')
    } else {
      swiper.autoplay?.start()
      // console.log('Starting autoplay')
    }
  }, [swiper, inView])

  const [activeSlide, setActiveSlide] = React.useState(0)
  // React.useEffect(() => {
  //   if (swiper) {
  //     swiper.on('slideChange', () => {
  //       const { activeIndex } = swiper
  //       setActiveSlide(activeIndex)
  //     })
  //     return () => {
  //       swiper.off('slideChange')
  //     }
  //   }
  // }, [swiper, setActiveSlide])

  const tts = (
    <Graphic
      isActiveSlide={
        (showSwiper && activeSlide === 0) || (!showSwiper && autoLabel === 0)
      }
      swiper={showSwiper}
      y={80}
      width={180}
      labelPoints={[
        { x: 153, y: -160 },
        { x: 153, y: -220 },
        { x: -15, y: -220 },
      ]}
      bubble={<Bubble width={45} x={130} y={-233} />}
      labelPosition={{ x: -10, y: -235 }}
      labelText={intl.formatMessage({ id: 'landing.tts' })}
      className="tts"
    >
      <TTS />
    </Graphic>
  )
  const asr = (
    <Graphic
      isActiveSlide={
        (showSwiper && activeSlide === 1) || (!showSwiper && autoLabel === 1)
      }
      swiper={showSwiper}
      x={250}
      y={30}
      width={250}
      labelPoints={[
        { x: 115, y: -223 },
        { x: 115, y: -180 },
        { x: 345, y: -180 },
      ]}
      bubble={<Bubble width={45} x={92} y={-296} />}
      labelPosition={{ x: 140, y: -195 }}
      labelText={intl.formatMessage({ id: 'landing.asr' })}
      className="asr"
    >
      <ASR />
    </Graphic>
  )
  const ivr = (
    <Graphic
      isActiveSlide={
        (showSwiper && activeSlide === 2) || (!showSwiper && autoLabel === 2)
      }
      swiper={showSwiper}
      x={200}
      y={200}
      width={120}
      labelPoints={[
        { x: 98, y: -187 },
        { x: 98, y: -120 },
        { x: 230, y: -120 },
      ]}
      bubble={<Bubbles width={73} x={47} y={-271} />}
      labelPosition={{ x: 110, y: -135 }}
      labelText={intl.formatMessage({ id: 'landing.ivr' })}
      className="ivr"
    >
      <IVR />
    </Graphic>
  )
  const cat = (
    <Graphic
      isActiveSlide={
        (showSwiper && activeSlide === 3) || (!showSwiper && autoLabel === 3)
      }
      swiper={showSwiper}
      x={470}
      y={250}
      width={110}
      labelPoints={[
        { x: 88, y: -40 },
        { x: 88, y: 30 },
        { x: -75, y: 30 },
      ]}
      bubble={<Bubble width={45} x={65} y={-113} />}
      labelPosition={{ x: -70, y: 17 }}
      labelText={intl.formatMessage({ id: 'landing.stc' })}
      hideCircle
      className="stc"
    >
      <Cat />
    </Graphic>
  )

  if (showSwiper) {
    // const swiperProps = {
    //   spaceBetween: 0,
    //   slidesPerView: 'auto',
    //   centeredSlides: true,
    //   loop: false,
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    //   },
    //   autoplay: {
    //     delay: 4000,
    //     disableOnInteraction: false,
    //   },
    //   a11y,
    // } as any
    // console.log('InView', inView)
    // if (inView) {
    //   // only autoplay when visible
    //   swiperProps.autoplay = {
    //     delay: 4000,
    //     disableOnInteraction: false,
    //   }
    // }
    // console.log(swiperProps)
    // return (
    //   <SwiperWrapper ref={ref}>
    //     <Swiper {...swiperProps} getSwiper={setSwiper}>
    //       <Slide>{tts}</Slide>
    //       <Slide>{asr}</Slide>
    //       <Slide>{ivr}</Slide>
    //       {/* <Slide>{cat}</Slide> */}
    //     </Swiper>
    //   </SwiperWrapper>
    // )
    return (
      <SwiperWrapper ref={ref}>
        <Swiper
          spaceBetween={0}
          slidesPerView="auto"
          centeredSlides
          loop={false}
          pagination={{ clickable: true }}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          a11y={a11y}
          onSwiper={swiper => setSwiper(swiper)}
          onSlideChange={function (this: SwiperCore) {
            const { activeIndex } = this
            setActiveSlide(activeIndex)
          }}
        >
          <SwiperSlide>
            <Slide>{tts}</Slide>
          </SwiperSlide>
          <SwiperSlide>
            <Slide>{asr}</Slide>
          </SwiperSlide>
          <SwiperSlide>
            <Slide>{ivr}</Slide>
          </SwiperSlide>
        </Swiper>
      </SwiperWrapper>
    )
  }

  return (
    <Wrapper ref={ref}>
      {tts}
      {asr}
      {ivr}
      {cat}
    </Wrapper>
  )
}

export default Graphics
