import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
// import BackgroundImage from 'gatsby-background-image'
// import GatsbyImage from 'gatsby-image'
import Image from 'gatsby-image'
import { Maybe, File } from '../../../types/graphql-types'

interface Props {
  children: React.ReactNode
}

// const Image = styled(GatsbyImage)`
//   position: absolute !important;
//   top: 0;
//   left: 0;
//   width: 100%;
// `
const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
`

const BackgroundSection: React.FC<Props> = ({ children }: Props) => {
  const data = useStaticQuery<{ desktop: Maybe<File> }>(graphql`
    query TestimonialsBackgroundImage {
      desktop: file(relativePath: { eq: "quotes.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  const fluid = data.desktop!.childImageSharp!.fluid!
  // return (
  //   <BackgroundImage
  //     Tag="section"
  //     fluid={imageData}
  //     backgroundColor={`rgb(131, 178, 203)`}
  //     className={className}
  //     preserveStackingContext
  //   >
  //     {children}
  //   </BackgroundImage>
  // )
  return (
    <Wrapper>
      <Image
        fluid={fluid as any}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          maxWidth: 'none',
          maxHeight: 'none',
        }}
      />
      {children}
    </Wrapper>
  )
}

// const StyledBackgroundSection = styled(BackgroundSection)`
//   width: 100%;
//   background-position: top center;
//   background-repeat: no-repeat;
//   background-size: cover;
// `

// export default StyledBackgroundSection

export default BackgroundSection
