import React from 'react'
import Markdown from '../Markdown'
import styled from 'styled-components'

interface Props {
  title: string
  description: string
  testimonial?: {
    Quote: string
    QuoteExcerpt?: string | null
    Author: string
    AuthorRole: string
  }
  company: string
}

const Wrapper = styled.div`
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  .title {
    font-weight: bold;
    p {
      margin-top: 0;
    }
  }
  .quote,
  .content {
    p {
      display: inline;
    }
  }
  .quote {
    padding-bottom: 1.5rem;
    font-style: italic;
    &:before {
      display: inline;
      content: open-quote;
    }
    &:after {
      display: inline;
      content: close-quote;
    }
  }
`
const Author = styled.div`
  font-weight: bold;
`
const AuthorDetails = styled.div``

const Testimonial: React.FC<Props> = (props: Props) => {
  const { title = '', description = '', testimonial, company } = props
  let details = <Markdown className="content" source={description} />
  if (testimonial) {
    const { QuoteExcerpt, Quote, Author: author, AuthorRole } = testimonial
    details = (
      <>
        <Markdown className="quote" source={QuoteExcerpt || Quote} />
        <Author>{author}</Author>
        <AuthorDetails>
          {AuthorRole}, {company}
        </AuthorDetails>
      </>
    )
  }
  return (
    <Wrapper>
      <Markdown className="title" source={title} />
      {details}
    </Wrapper>
  )
}

export default React.memo(Testimonial)
