import React from 'react'
import styled from 'styled-components'
import Graphics from './Graphics'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'
import BaseHeader from '../Header'
import {
  Waves,
  MightyBlue,
  Button as BaseButton,
  filterButtonProps,
} from '@aristech/components'
import { useInView } from 'react-intersection-observer'

const Link = filterButtonProps('a')

const Content = styled.div`
  -js-display: flex;
  display: flex;
  max-width: ${props => props.theme.mediumBreakpoint}px;
  margin: 0 auto;
  margin-top: calc(-90px - 2em);
  box-sizing: border-box;
  padding: 3em;
  padding-top: 10em;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    flex-direction: column;
    padding-top: 7.5rem;
  }
`
const Header = styled(BaseHeader)`
  margin-bottom: 0;
  font-weight: 300;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    font-size: 2rem;
    text-align: center;
  }
`
const Transcript = styled.p`
  font-weight: 300;
  color: rgba(255, 255, 255, 0.2);
  font-size: 1.25em;
  padding: 1em 0;
  margin: 0;
  margin-bottom: 0.8em;
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    padding: 0;
  }
`
const Button = styled(BaseButton)`
  display: inline-block;
  border-color: transparent;
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    display: none;
  }
`
const Text = styled.div`
  color: white;
  margin-right: 4em;
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    text-align: center;
    margin-right: 0;
  }
`
const Wrapper = styled.section`
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    .tts {
      margin-top: 30px;
      margin-bottom: -30px;
    }
    .asr {
      margin-left: -40px;
      margin-right: 40px;
    }
    .ivr {
      margin-left: -30px;
      margin-right: 30px;
    }
    .stc {
      margin-left: 20px;
      margin-right: -20px;
      margin-top: 30px;
      margin-bottom: -30px;
    }
  }
`

interface Props {
  intl: IntlShape
  setInView: (inView: boolean) => void
}

const Landing: React.FC<Props> = ({ intl, setInView }: Props) => {
  const [ref, inView /*, entry*/] = useInView({ threshold: 0.5 })
  React.useEffect(() => {
    setInView(inView)
  }, [inView])
  return (
    <MightyBlue>
      <Wrapper ref={ref}>
        <Content>
          <Text>
            <Header
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'landing.header',
                }),
              }}
              as="h1"
            />
            <Transcript tabIndex={-1} aria-hidden="true">
              {intl.formatMessage({ id: 'landing.subheader' })}
            </Transcript>
            <Button as={Link} href={'#overview'} color="none">
              {intl.formatMessage({ id: 'landing.button' })}
            </Button>
          </Text>
          <Graphics />
        </Content>
      </Wrapper>
      <Waves animated />
    </MightyBlue>
  )
}

export default injectIntl(Landing)
